html
{
  box-sizing: border-box;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
*,
*:before,
*:after
{
  box-sizing: inherit;
}
body {
  margin:0;
  background: #fafafa;
}
